import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state() {
    return {
      loginStatus: false
    }
  },
  getters: {},
  mutations: {
    changeLoginStatus(state, status) {
      state.loginStatus = status
    }
  },
  actions: {},
  modules: {}
})