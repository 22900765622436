import Vue from "vue";
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ManageView from '../views/ManageView.vue'
import AddCourse from '../views/manage/AddCourse.vue'
import DeleteCourse from '../views/manage/DeleteCourse.vue'
import MoveCourse from '../views/manage/MoveCourse.vue'
import UploadCourse from '../views/manage/UploadCourse.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/manage',
    name: 'manage',
    component: ManageView,
    children: [
      {
        path: 'add',
        name: 'AddCourse',
        component: AddCourse
      },
      {
        path: 'delete',
        name: 'DeleteCourse',
        component: DeleteCourse
      },
      {
        path: 'move',
        name: 'MoveCourse',
        component: MoveCourse
      },
      {
        path: 'upload',
        name: 'UploadCourse',
        component: UploadCourse
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
