<template>
  <div class="DeleteCourse">
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="周次选择" prop="which_weeks">
        <el-select v-model="ruleForm.which_weeks" multiple collapse-tags placeholder="请选择周次">
          <el-option v-for="item in week_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="星期选择" prop="week_days">
        <el-checkbox-group v-model="ruleForm.week_days">
          <el-checkbox label=1>星期一</el-checkbox>
          <el-checkbox label=2>星期二</el-checkbox>
          <el-checkbox label=3>星期三</el-checkbox>
          <el-checkbox label=4>星期四</el-checkbox>
          <el-checkbox label=5>星期五</el-checkbox>
          <el-checkbox label=6>星期六</el-checkbox>
          <el-checkbox label=0>星期日</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="上课时间" prop="which_lessons">
        <el-checkbox-group v-model="ruleForm.which_lessons">
          <el-checkbox label=1>上午1</el-checkbox>
          <el-checkbox label=2>上午2</el-checkbox>
          <el-checkbox label=3>下午1</el-checkbox>
          <el-checkbox label=4>下午2</el-checkbox>
          <el-checkbox label=5>晚上1</el-checkbox>
          <el-checkbox label=6>晚上2</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="教室号" prop="classroomNumber">
        <el-input v-model="ruleForm.classroomNumber" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click="submitForm('ruleForm')">删除</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'DeleteCourse',
  data() {
    return {
      ruleForm: {
        week_days: [],
        which_weeks: [],
        which_lessons: [],
        classroomNumber: ''
      },
      week_options: [{
        label: '第1周',
        value: '1'
      },
      {
        label: '第2周',
        value: '2'
      },
      {
        label: '第3周',
        value: '3'
      },
      {
        label: '第4周',
        value: '4'
      },
      {
        label: '第5周',
        value: '5'
      },
      {
        label: '第6周',
        value: '6'
      },
      {
        label: '第7周',
        value: '7'
      },
      {
        label: '第8周',
        value: '8'
      },
      {
        label: '第9周',
        value: '9'
      },
      {
        label: '第10周',
        value: '10'
      },
      {
        label: '第11周',
        value: '11'
      },
      {
        label: '第12周',
        value: '12'
      },
      {
        label: '第13周',
        value: '13'
      },
      {
        label: '第14周',
        value: '14'
      },
      {
        label: '第15周',
        value: '15'
      },
      {
        label: '第16周',
        value: '16'
      },
      {
        label: '第17周',
        value: '17'
      },
      {
        label: '第18周',
        value: '18'
      },
      {
        label: '第19周',
        value: '19'
      }
      ],
      rules: {
        which_weeks: [{ required: true, message: '请选择要添加的周次', trigger: 'change' }],
        week_days: [{ required: true, message: '请至少选择一个选项', trigger: 'change' }],
        which_lessons: [{ required: true, message: '请至少选择一个选项', trigger: 'change' }],
        classroomNumber: [{ required: true, message: '请输入教室号', trigger: 'change' },
        { min: 4, max: 4, message: '教室号长度为4', trigger: 'blur' }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('/api/isNull', this.ruleForm).then(response => {
            if (response.data.isNull == 0) {
              this.$confirm('查询到' + response.data.notNull.length + '条记录，是否确定删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'info'
              }).then(() => {
                axios.post('/api/deleteCourse', response.data.notNull).then(response => {
                  if (response.data.ok) {
                    this.$message({
                      type: 'success',
                      message: '删除成功！'
                    });
                  } else {
                    this.$message({
                      type: 'warning',
                      message: '后端未知错误！'
                    });
                  }
                })
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消删除'
                });
              });
            } else {
              this.$message({
                type: 'info',
                message: '所选条件无记录，无需删除。'
              });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>