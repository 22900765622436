<template>
  <div class="MoveCourse">
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="初始周次" prop="from_which_week">
        <el-select v-model="ruleForm.from_which_week" placeholder="请选择周次">
          <el-option v-for="item in week_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="初始星期" prop="from_week_day">
        <el-radio v-model="ruleForm.from_week_day" label=1>星期一</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=2>星期二</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=3>星期三</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=4>星期四</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=5>星期五</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=6>星期六</el-radio>
        <el-radio v-model="ruleForm.from_week_day" label=0>星期日</el-radio>
      </el-form-item>

      <el-form-item label="目标周次" prop="to_which_week">
        <el-select v-model="ruleForm.to_which_week" placeholder="请选择周次">
          <el-option v-for="item in week_options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目标星期" prop="to_week_day">
        <el-radio v-model="ruleForm.to_week_day" label=1>星期一</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=2>星期二</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=3>星期三</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=4>星期四</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=5>星期五</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=6>星期六</el-radio>
        <el-radio v-model="ruleForm.to_week_day" label=0>星期日</el-radio>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  name: 'MoveCourse',
  data() {
    return {
      ruleForm: {
        from_week_day: '',
        from_which_week: '',
        to_week_day: '',
        to_which_week: ''
      },
      week_options: [{
        label: '第1周',
        value: '1'
      },
      {
        label: '第2周',
        value: '2'
      },
      {
        label: '第3周',
        value: '3'
      },
      {
        label: '第4周',
        value: '4'
      },
      {
        label: '第5周',
        value: '5'
      },
      {
        label: '第6周',
        value: '6'
      },
      {
        label: '第7周',
        value: '7'
      },
      {
        label: '第8周',
        value: '8'
      },
      {
        label: '第9周',
        value: '9'
      },
      {
        label: '第10周',
        value: '10'
      },
      {
        label: '第11周',
        value: '11'
      },
      {
        label: '第12周',
        value: '12'
      },
      {
        label: '第13周',
        value: '13'
      },
      {
        label: '第14周',
        value: '14'
      },
      {
        label: '第15周',
        value: '15'
      },
      {
        label: '第16周',
        value: '16'
      },
      {
        label: '第17周',
        value: '17'
      },
      {
        label: '第18周',
        value: '18'
      },
      {
        label: '第19周',
        value: '19'
      }
      ],
      rules: {
        from_week_day: [{ required: true, message: '请选择移动课程的初始周次', trigger: 'change' }],
        from_which_week: [{ required: true, message: '请选择移动课程的初始星期', trigger: 'change' }],
        to_week_day: [{ required: true, message: '请选择移动课程的目标周次', trigger: 'change' }],
        to_which_week: [{ required: true, message: '请选择移动课程的目标星期', trigger: 'change' }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('/api/moveCourse', this.ruleForm).then(response => {
            if (response.data.ok) {
              this.$message({
                type: 'success',
                message: '调课成功'
              });
            } else {
              switch (response.data.state) {
                case 1:
                  this.$message({
                    type: 'warning',
                    message: '初始日期课程为空！'
                  });
                  break;
                case 2:
                  this.$message({
                    type: 'warning',
                    message: '目标日期有课程信息！'
                  });
              }
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>