<template>
  <el-row style="text-align: center;">
    <!-- 文件上传 -->
    <el-col :span="8" style="height: 80vh; overflow: auto;">
      <el-upload ref="upload" action="/api/UploadFile" multiple :auto-upload="false">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>
      </el-upload>
    </el-col>
    <!-- 服务器文件列表 -->
    <el-col :span="8">
      <div style="height: 70vh;overflow: auto; margin: 10px; border: 2px dotted black;">
        <el-tag style="margin: 5px;" v-for="(filename, index) in fileNames" :key="index">{{ filename }}</el-tag>
      </div>
      <el-button type="warning" @click="deleteFile()">删除所有文件</el-button>
    </el-col>
    <!-- 解析课表 -->
    <el-col :span="8">
      <div style="height: 50vh;overflow: auto; margin: 10px; border: 2px dotted black;">
        <el-tag style="margin: 5px;" v-for="(value, key, index) in fileList" :key="index">{{ key }}解析出{{ value
        }}条课程记录</el-tag><br />
      </div>
      <div style="height: 18vh;overflow: auto; margin: 10px; border: 2px dotted black;">
        <div v-for="(ef, index) in errorFile" :key="index">
          <el-tag type="danger" style="margin: 5px;">{{ ef }}</el-tag><br />
        </div>
      </div>
      <el-button type="primary" @click="analyseTable()">解析课表数据</el-button>
      <el-tooltip class="item" effect="dark" v-if="!dataOk" content="请先解析课表数据" placement="top-start">
        <el-button type="success" :disabled="!dataOk" @click="ImportTable()">导入数据库</el-button>
      </el-tooltip>
      <el-button type="success" v-else @click="ImportTable()">导入数据库</el-button>

    </el-col>
  </el-row>
</template>
<script>
import axios from 'axios';
export default {
  name: 'UploadCourse',
  data() {
    return {
      fileNames: [],
      fileList: {},
      errorFile: [],
      dataOk: false
    }
  },
  methods: {
    getfileNames() {
      this.dataOk = false
      axios.get('/api/ManageFileServlet').then(response => {
        if (response.data.isNull) {
          this.fileNames = []
        } else {
          this.fileNames = response.data.fileNames
        }
      })
    },
    deleteFile() {
      this.dataOk = false
      axios.post('/api/ManageFileServlet').then(response => {
        if (response.data.ok) {
          this.fileNames = [];
          this.fileList = {}
          this.errorFile = []
          this.dataOk = false
          this.$message({
            type: 'success',
            message: '删除成功'
          });
        }
      })
    },
    analyseTable() {
      axios.get('/api/ImportTableServlet').then(response => {
        if (response.data.ok == 1) {
          this.dataOk = true
          this.fileList = response.data.info
          this.$message({
            type: 'success',
            message: '解析正常，可导入数据库'
          });
        } else {
          this.dataOk = false
          switch (response.data.error) {
            case 1:
              this.$message({
                type: 'warning',
                message: '服务器文件夹为空'
              });
              break;
            case 2:
              this.fileList = response.data.info
              this.errorFile = response.data.errorFile
              this.$message({
                type: 'warning',
                message: '解析表格时出现错误'
              });
              break;
          }
        }
      })
    },
    ImportTable() {
      axios.get('/api/isNull').then(response => {
        if (response.data.dataNumber > 0) {
          this.$confirm('数据库现有记录' + response.data.dataNumber + '条，是否清除？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            axios.post('/api/DeleteAllCourseServlet').then(response => {
              if (response.data.ok) {
                this.$message({
                  type: 'success',
                  message: '删除成功,请再次点击导入数据'
                });
              } else {
                this.$message({
                  type: 'danger',
                  message: '删除失败'
                });
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消导入'
            });
          });
        } else {
          this.dataOk = false
          this.$confirm('数据库无记录，可直接导入数据', '提示', {
            confirmButtonText: '确定导入',
            cancelButtonText: '取消',
            type: 'success'
          }).then(() => {
            const loading = this.$loading({
              lock: true,
              text: '正在添加中，请耐心等待。',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            axios.post('/api/ImportTableServlet').then(response => {
              loading.close()
              if (response.data.ok) {
                this.$message({
                  type: 'success',
                  message: '导入成功'
                });
              } else {
                this.$message({
                  type: 'danger',
                  message: '导入失败'
                });
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消添加'
            });
          });
        }
      })
    },
    submitUpload() {
      this.$refs.upload.submit()
      this.dataOk = false
      setTimeout(() => {
        this.getfileNames()
        this.$refs.upload.clearFiles()
      }, 2000)
    }
  },
  created() {
    this.getfileNames()
  }
}
</script>
<style></style>
