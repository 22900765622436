<template>
  <div>
    <el-row :gutter="3">
      <el-col :span="3">
        <div class="grid-content"><span>{{ classroomNumber }}</span></div>
      </el-col>

      <el-col :span="3" :offset="1">
        <div class="grid-content have-class" v-if="whichLesson.includes(1)" @click="testClick(1)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content have-class" v-if="whichLesson.includes(2)" @click="testClick(2)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
      <el-col :span="3" :offset="1">
        <div class="grid-content have-class" v-if="whichLesson.includes(3)" @click="testClick(3)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content have-class" v-if="whichLesson.includes(4)" @click="testClick(4)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
      <el-col :span="3" :offset="1">
        <div class="grid-content have-class" v-if="whichLesson.includes(5)" @click="testClick(5)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content have-class" v-if="whichLesson.includes(6)" @click="testClick(6)">
          <span>有课</span>
        </div>
        <div class="grid-content no-class" v-else></div>
      </el-col>
    </el-row>
    <el-dialog title="班级课程信息" :visible.sync="dialogTableVisible" width="95%">
      <el-descriptions :column="1" border>
        <el-descriptions-item label="课程名">{{ infoData.lesson_name }}</el-descriptions-item>
        <el-descriptions-item label="任课老师">{{ infoData.teacher_name }}</el-descriptions-item>
        <el-descriptions-item label="课程类型">{{ infoData.lesson_type }}</el-descriptions-item>
        <el-descriptions-item label="班级">{{ infoData.class_name }}</el-descriptions-item>
        <el-descriptions-item label="人数">{{ infoData.number_of_student }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'ContentPage',
  props: {
    classroomNumber: String,
    whichLesson: Array,
    selectDate: Date,
    whichWeek: Number
  },
  data() {
    return {
      dialogTableVisible: false,
      infoData: {}
    }
  },
  methods: {
    testClick(whichLesson) {
      axios.get('/api/aboutCourse', {
        params: {
          'whichWeek': this.whichWeek,
          'weekDay': this.selectDate.getDay(),
          'classroom_number': this.classroomNumber,
          'which_lesson': whichLesson
        }
      }).then((res) => {
        this.infoData = res.data
      }).catch(function (error) {
        console.log(error);
      })
      this.dialogTableVisible = true
    },
    testGet() {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to thmponent only -->
<style scoped>
* {
  padding-top: 2px;
}

.grid-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  border-radius: 5px;
  background-color: antiquewhite;
}

.have-class {
  background-color: aquamarine;
}

.no-class {
  background-color: antiquewhite;
}

span {
  writing-mode: vertical-lr;
  text-orientation: upright;
}
</style>
