<template>
  <div class="HomeView">
    <!-- 选择列begin -->
    <el-row :gutter="5">
      <el-col :span="10">
        <div class="grid-content">
          <el-date-picker v-model="selectDate" type="date" style="width: inherit;" placeholder="选择日期">
          </el-date-picker>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content">
          <el-select v-model="filterZone" placeholder="请选择" style="width: inherit;">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content" @click="manage()"><el-tag type="info">{{ weeks[selectDate.getDay()] }}</el-tag></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content" @click="manage()"><el-tag type="info">第{{ whichWeek }}周</el-tag></div>
      </el-col>
    </el-row>
    <!-- 选择列end -->
    <!-- 表头begin -->
    <el-row :gutter="3">
      <el-col :span="3">
        <div class="grid-content menu"><span class="text-down">教室号</span></div>
      </el-col>
      <el-col :span="3" :offset="1">
        <div class="grid-content menu"><span class="text-down">上午一</span></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content menu"><span class="text-down">上午二</span></div>
      </el-col>
      <el-col :span="3" :offset="1">
        <div class="grid-content menu"><span class="text-down">下午一</span></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content menu"><span class="text-down">下午二</span></div>
      </el-col>
      <el-col :span="3" :offset="1">
        <div class="grid-content menu"><span class="text-down">晚上一</span></div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content menu"><span class="text-down">晚上二</span></div>
      </el-col>
    </el-row>
    <!-- 表头end -->
    <!-- 查询内容begin -->
    <ContentPage v-for="( whichLesson, classroomNumber) in contentDates" :key="classroomNumber"
      :classroom-number="classroomNumber" :which-lesson="whichLesson" :select-date="selectDate" :which-week="whichWeek">
    </ContentPage>
    <!-- 查询内容end -->
    <!-- 备案号begin -->
    <div style="width: 100%; text-align: center;">
      <el-link href="https://beian.miit.gov.cn" target="_blank" type="primary">渝ICP备2024028104号</el-link>
    </div>
    <!-- 备案号end -->
  </div>
</template>
<script>
import axios from 'axios'
import ContentPage from '../components/ContentPage.vue'
export default {
  name: "HomeView",
  components: {
    ContentPage
  },
  data() {
    return {
      options: [{
        value: 'all',
        label: '全部'
      }, {
        value: 'C',
        label: 'C区'
      }, {
        value: 'D',
        label: 'D区'
      }, {
        value: 'E',
        label: 'E区'
      }],
      weeks: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      filterZone: 'all',
      selectDate: new Date(),
      contentDates: {}
    }
  },
  methods: {
    testGet(whichWeek, weekDay, district) {
      axios.get('api/query', {
        params: {
          'which-week': whichWeek,
          'week-day': weekDay,
          'district': district
        }
      }).then((res) => {
        this.contentDates = res.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    manage() {
      this.$prompt('请输入管理员密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d{4}$/,
        inputErrorMessage: '密码格式错误'
      }).then(({ value }) => {
        if (value == '8888') {
          this.$store.commit('changeLoginStatus', true)
          this.$router.replace({ path: '/manage' })
        } else {
          this.$message({
            type: 'warning',
            message: '密码错误'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });

    }
  },
  computed: {
    whichWeek() {
      return Math.trunc((this.selectDate - new Date('2024-09-01')) / 24 / 60 / 60 / 1000 / 7 + 1)
    }
  },
  watch: {
    whichWeek(newValue) {
      if (newValue < 0 || newValue > 19) {
        this.$message({
          message: '选择日期超出了本学期的课表周期',
          type: 'warning'
        });
        this.selectDate = new Date()
      }
    },
    selectDate() {
      this.testGet(this.whichWeek, this.selectDate.getDay(), this.filterZone)
    },
    filterZone() {
      this.testGet(this.whichWeek, this.selectDate.getDay(), this.filterZone)
    },
    contentDates(newValue) {
      if (Object.keys(newValue).length == 0) {
        this.$message({
          message: '今天的课程安排为空',
          type: 'success'
        });
      }
    }
  },
  created() {
    this.testGet(this.whichWeek, this.selectDate.getDay(), this.filterZone)
  }
}
</script>
<style scoped>
.grid-content {
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  background-color: rgb(169, 212, 250);
}

.text-down {
  writing-mode: vertical-lr;
  text-orientation: upright;
}
</style>