<template>
  <div class="LoginView" style="height: 100vh; display: flex; justify-content: center; align-items: center;">
    <div style="width: 60vw; max-width: 500px;text-align: center;">
      <el-input v-model="user" placeholder="请输入用户名"></el-input>
      <el-input placeholder="请输入密码" v-model="password" show-password style="margin-top: 20px;"></el-input>
      <el-button type="primary" style="margin-top: 20px; width: 100%;">登录</el-button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoginView',
  data() {
    return {
      user: '',
      password: ''
    }
  }
}
</script>